import { Navigate, Outlet } from "react-router-dom";
import StorageBox from "../../core/storage";

export default function PrivateRoute() {
  return StorageBox.getToken() !== null &&
    StorageBox.getUser() !== null &&
    StorageBox.getOrganization() !== null ? (
    <Outlet />
  ) : (
    <Navigate to={`/`} />
  );
}
