import CryptoJS from "crypto-js";

const SECRET_KEY = "kelemm-application-secrete-key";
const expiry = new Date().getTime() + 8 * 60 * 60 * 1000;

const StorageBox = {
  getToken: (): string | null => {
    try {
      const encryptedData: any = localStorage.getItem("token");
      if (encryptedData) {
        const { token, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );

        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("token");
          return null;
        }

        return token;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting token:", error);
      return null;
    }
  },
  setToken: (token: string): boolean => {
    try {
      const dataToEncrypt = JSON.stringify({ token, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("token", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting token:", error);
      return false;
    }
  },
  getUser: () => {
    try {
      const encryptedData: any = localStorage.getItem("user");
      if (encryptedData) {
        const { userData, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );

        // Check if the user data has expired
        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("user");
          return null;
        }

        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting user:", error);
      return null;
    }
  },
  setUser: (user: any): boolean => {
    try {
      const dataToEncrypt = JSON.stringify({ userData: user, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("user", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting user:", error);
      return false;
    }
  },
  getOrganization: () => {
    try {
      const encryptedData: any = localStorage.getItem("organization");
      if (encryptedData) {
        const { userData, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );

        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("organization");
          return null;
        }

        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting user:", error);
      return null;
    }
  },
  setOrganization: (user: any): boolean => {
    try {
      const dataToEncrypt = JSON.stringify({ userData: user, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("organization", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting user:", error);
      return false;
    }
  },
  getSubscription: () => {
    try {
      const encryptedData: any = localStorage.getItem("subscription");
      if (encryptedData) {
        const { userData, expiry } = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );
        if (expiry && new Date().getTime() > expiry) {
          localStorage.removeItem("subscription");
          return null;
        }
        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error while decrypting user:", error);
      return null;
    }
  },
  setSubscription: (user: any): boolean => {
    try {
      const dataToEncrypt = JSON.stringify({ userData: user, expiry });
      const encryptedData = CryptoJS.AES.encrypt(
        dataToEncrypt,
        SECRET_KEY
      ).toString();
      localStorage.setItem("subscription", encryptedData);
      return true;
    } catch (error) {
      console.error("Error while encrypting user:", error);
      return false;
    }
  },

  deleteToken: (): boolean => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("organization");
    localStorage.removeItem("subscription");
    return true;
  },
};

export default StorageBox;
