import { useState } from "react";
import Context from "./context";
import { IContextGetData } from "../utils/interface";
import moment from "moment";

export default function Provider({ children }: { children: React.ReactNode }) {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

  const [membersState, setMembersState] = useState<IContextGetData>({
    page: 1,
    size: 50,
    loading: true,
    data: null,
  });
  const [membersAttendanceState, setMembersAttendanceState] =
    useState<IContextGetData>({
      page: 1,
      size: 50,
      loading: true,
      data: null,
    });
  const [statisticsState, setStatisticsState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [subscriptionHistoryState, setSubscriptionHistoryState] =
    useState<IContextGetData>({
      page: 1,
      size: 20,
      loading: true,
      data: null,
    });

  const [paymentConfigsState, setPaymentConfigsState] = useState({
    loading: true,
    data: null,
  });

  /**Dashboard Graph */
  const [graph, setGraph] = useState("WEEK");
  const [selector, setSelector] = useState<"ANNUALLY" | "WEEKLY" | "MONTHLY">(
    "WEEKLY"
  );
  const [date, setDate] = useState(moment().format("YYYY-[W]WW"));
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [graphData, setGraphData] = useState<{ name: string; total: number }[]>(
    []
  );
  type IGraphTypes = "Payment" | "Attendance";
  const [currentGraph, setCurrentGraph] = useState<IGraphTypes>("Payment");

  return (
    <Context.Provider
      value={{
        /**Dashboard Graph */
        graph,
        setGraph,
        selector,
        setSelector,
        date,
        setDate,
        loading,
        setLoading,
        reloading,
        setReloading,
        graphData,
        setGraphData,
        currentGraph,
        setCurrentGraph,

        sidebarVisible,
        setSidebarVisible,

        statisticsState,
        setStatisticsState,

        membersState,
        setMembersState,

        membersAttendanceState,
        setMembersAttendanceState,

        subscriptionHistoryState,
        setSubscriptionHistoryState,

        paymentConfigsState,
        setPaymentConfigsState,
      }}
    >
      {children}
    </Context.Provider>
  );
}
